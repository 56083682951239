<template>
  <footer class="footer">
    <div class="container">
      <div class="company-info">
        <p>{{ $t('footer.contact') }}</p>
        <p>{{ $t('footer.emailTitle') }} <a href="mailto:info@openuni.financial" target="_blank">info@openuni.financial</a></p>
        <p>{{ $t('footer.telegramTitle') }} <a :href="TELEGRAM_URL" target="_blank">@DianaOpenUni</a></p>
        <p>Copyright &copy; 2024 OpenUni.</p>
      </div>
    </div>
  </footer>
</template>

<script>
import { TELEGRAM_URL } from '@/constants.js';
export default {
  name: 'SiteFooter',
    data() {
    return {
      TELEGRAM_URL
    };
  }
};
</script>
<style scoped>
.footer {
  background-color: #1f1f1f; /* Dark background */
  color: #e0e0e0; /* Light text color */
  padding: 1rem 0;
  text-align: center;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-links {
  margin-bottom: 1rem;
}
.footer-links a {
  color: #e0e0e0; /* Light text color */
  margin: 0 1rem;
  text-decoration: none;
  transition: color 0.3s ease;
}
.footer-links a:hover {
  color: #f39c12; /* Accent color */
}
.company-info p {
  margin-bottom: 0.5rem;
}
.company-info a {
  color: #f39c12; /* Accent color */
  text-decoration: none;
}
.company-info a:hover {
  text-decoration: underline;
}
</style>