<template>
  <div id="app">
    <!-- Navigation Bar -->
    <NavBar />

    <!-- Hero Section (Banner Section) -->
    <HeroSection />

    <!-- Products Demo Section -->
    <ProductsDemo />

    <!-- Proven Success Section -->
    <ProvenSuccess />

    <!-- About Us Section -->
    <AboutUsSection />

    <!-- Footer Section -->
    <SiteFooter />
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import HeroSection from './components/HeroSection.vue';
import ProductsDemo from './components/ProductsDemo.vue';
import ProvenSuccess from './components/ProvenSuccess.vue';
import AboutUsSection from './components/AboutUsSection.vue';
import SiteFooter from './components/SiteFooter.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    HeroSection,
    ProductsDemo,
    ProvenSuccess,
    AboutUsSection,
    SiteFooter,
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Roboto:wght@400;700&display=swap');

#app {
  font-family: 'Poppins', sans-serif; /* Primary Font */
  font-size: 16px; /* Base font size */
  background-color: #1f1f1f; /* Dark background */
  color: #e0e0e0; /* Light text color */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 80px; /* Adjust this value based on the height of your navbar */
}

@media (max-width: 768px) {
  #app {
    padding-top: 60px; /* Adjust this value for smaller screens */
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif; /* Secondary Font for headings */
}
</style>