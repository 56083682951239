<template>
  <section id="about-us" class="about-us-section">
    <div class="container">
      <!-- Management Team Section -->
      <div class="team-section">
        <h2>{{ $t('aboutUs.managementTeam') }}</h2>
        <div class="team-members">
          <div class="team-member" v-for="member in teamMembers" :key="member.name">
            <h3>{{ member.name }}</h3>
            <p>{{ member.title }}</p>
            <p>{{ member.introduction }}</p>
          </div>
        </div>
      </div>

      <!-- Our Partners Section -->
      <div class="our-partners">
        <h2>{{ $t('aboutUs.ourPartners') }}</h2>
        <div class="partner-logos">
          <div class="partner-logo" v-for="partner in partners" :key="partner.id">
            <img :src="partner.image" :class="{'small-logo': [1, 2, 4, 6].includes(partner.id)}" class="partner-logo-photo" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUsSection",
  data() {
    return {
      partners: [
        { id: 1, image: require('@/assets/Binance.svg') },
        { id: 2, image: require('@/assets/Bitget.svg') },
        { id: 3, image: require('@/assets/Bybit.svg') },
        { id: 4, image: require('@/assets/Gate.svg') },
        { id: 5, image: require('@/assets/HTX.svg') },
        { id: 6, image: require('@/assets/Kucoin.svg') },
        { id: 7, image: require('@/assets/OKX.svg') },
        { id: 8, image: require('@/assets/Mexc.svg') },
      ]
    };
  },
  computed: {
    teamMembers() {
      return [
        {
          name: "Michael Xie",
          title: this.$t('aboutUs.michaelXieTitle'),
          introduction: this.$t('aboutUs.michaelXieIntroduction')
        },
        {
          name: "Jason Cai",
          title: this.$t('aboutUs.jasonCaiTitle'),
          introduction: this.$t('aboutUs.jasonCaiIntroduction')
        },
      ];
    }
  },
  mounted() {
    const navbarHeight = document.querySelector('.navbar').offsetHeight;
    document.documentElement.style.setProperty('--scroll-margin-top', `${navbarHeight}px`);
  }
};
</script>

<style scoped>
.about-us-section {
  padding: 1rem 1rem; /* Reduce padding */
  background-color: #1f1f1f; /* Dark background */
  text-align: center;
  color: #e0e0e0; /* Light text color */
  scroll-margin-top: var(--scroll-margin-top); /* Use CSS variable for scroll margin */
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.team-section h2,
.our-partners h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem; /* Reduce bottom margin */
  color: #e0e0e0; /* Light text color */
}
.team-members {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Default to 2 columns */
  gap: 1rem; /* Add gap between items */
}
.team-member {
  padding: 1rem;
  text-align: left; /* Align text to the left */
}
.team-member h3 {
  margin-bottom: 0.1rem; /* Reduce space below the name */
}
.team-member p {
  margin-top: 0.1rem; /* Reduce space above the title */
  margin-bottom: 0.5rem; /* Reduce space below the title */
}
.partner-logos {
  display: grid;
  grid-template-columns: repeat(8, 1fr); /* 8 items per row */
  gap: 0.5rem; /* Add gap between items */
}
.partner-logo {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}
.partner-logo-photo {
  width: 10vw; /* Set width based on viewport width */
  max-width: 60px; /* Set a maximum width */
  height: auto; /* Maintain aspect ratio */
}
.small-logo {
  width: 8vw; /* Smaller width for specific logos */
  max-width: 40px; /* Set a maximum width for smaller logos */
  height: auto; /* Maintain aspect ratio */
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .team-members {
    grid-template-columns: repeat(2, 1fr); /* Two items per row on medium screens */
  }
  .partner-logos {
    grid-template-columns: repeat(8, 1fr); /* 8 items per row on medium screens */
  }
}

@media (max-width: 768px) {
  .team-members {
    grid-template-columns: repeat(1, 1fr); /* One item per row on smaller screens */
  }
  .partner-logos {
    grid-template-columns: repeat(8, 1fr); /* 8 items per row on smaller screens */
  }
}
</style>