import { createApp, watchEffect } from 'vue';
import App from './App.vue';
import i18n from './i18n';

const app = createApp(App).use(i18n);

const updateTitle = () => {
  document.title = i18n.global.t('appTitle');
};

// Watch for language changes to update the title
watchEffect(() => {
  updateTitle();
});

app.mount('#app');

// Set the initial title
updateTitle();