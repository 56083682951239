<template>
  <nav class="navbar">
    <div class="container">
      <div class="logo">
        <img class="dark-mode" src="@/assets/OpenUni.svg" alt="OpenUni Logo" />
        <a href="#" class="logo-text">OPENUNI</a>
      </div>
      <ul class="nav-links" :class="{ 'nav-links-open': isNavOpen }">
        <li><a href="#hero-section" @click="closeNav">{{ $t('navbar.home') }}</a></li>
        <li><a href="#products-demo" @click="closeNav">{{ $t('navbar.products') }}</a></li>
        <li><a href="#proven-success" @click="closeNav">{{ $t('navbar.provenSuccess') }}</a></li>
        <li><a href="#about-us" @click="closeNav">{{ $t('navbar.aboutUs') }}</a></li>
        <li class="language-switcher">
          <a class="language-toggle" @click="toggleLanguageMenu" :aria-expanded="isLanguageMenuOpen.toString()">
            {{ currentLanguageLabel }}
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" aria-hidden="true" focusable="false">
              <path d="M1.5 4L6 8L10.5 4" stroke-width="1.5" stroke="#e0e0e0"></path>
            </svg>
          </a>
          <ul v-if="isLanguageMenuOpen" class="language-menu">
            <li @click="changeLanguage('en')">English</li>
            <li @click="changeLanguage('zh-TW')">繁體中文</li>
          </ul>
        </li>
      </ul>
      <div class="hamburger-menu" @click="toggleNav">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'NavBar',
  data() {
    return {
      currentLocale: this.$i18n.locale,
      isLanguageMenuOpen: false,
      isNavOpen: false
    };
  },
  computed: {
    currentLanguageLabel() {
      return this.currentLocale === 'en' ? 'English' : '繁體中文';
    }
  },
  methods: {
    changeLanguage(locale) {
      this.$i18n.locale = locale;
      this.currentLocale = locale;
      this.isLanguageMenuOpen = false;
      localStorage.setItem('preferredLanguage', locale);
    },
    toggleLanguageMenu() {
      this.isLanguageMenuOpen = !this.isLanguageMenuOpen;
    },
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    },
    closeNav() {
      this.isNavOpen = false;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.closeNav();
      }
    }
  },
  mounted() {
    const savedLocale = localStorage.getItem('preferredLanguage');
    if (savedLocale) {
      this.changeLanguage(savedLocale);
    }
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
};
</script>
<style scoped>
/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Navbar Styles */
.navbar {
  width: 100%;
  background-color: #1f1f1f; /* Dark background */
  padding: 1rem 0; /* Reduce padding */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

/* Container Styles */
.container {
  max-width: 75rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center items vertically */
  padding: 0 1rem;
}

/* Logo Styles */
.logo {
  display: flex;
  align-items: center; /* Center items vertically */
}

.logo img {
  height: 2.5rem; /* Adjust the height as needed */
  width: auto; /* Maintain aspect ratio */
  margin-right: 0.625rem; /* Space between image and text */
  filter: brightness(10) contrast(1.2);
}

.logo img.dark-mode {
  filter: invert(1) hue-rotate(180deg); /* Apply filter for dark mode */
}

.logo-text {
  font-size: 2rem; /* Increase font size */
  font-weight: 900; /* Make the font bolder */
  color: #e0e0e0; /* Light text color */
  text-decoration: none;
}

.logo-text:hover {
  color: #f39c12; /* Accent color */
}

/* Navigation Links */
.nav-links {
  list-style: none;
  display: flex;
}

.nav-links li {
  margin-left: 1rem; /* Reduce space between links */
}

.nav-links a {
  text-decoration: none;
  font-size: 1rem;
  color: #e0e0e0; /* Light text color */
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #f39c12; /* Accent color */
}

/* Language Switcher */
.language-switcher {
  position: relative;
}

.language-toggle {
  cursor: pointer;
  color: #e0e0e0;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.language-toggle svg {
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

.language-toggle[aria-expanded="true"] svg {
  transform: rotate(180deg);
}

.language-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #1f1f1f;
  border: 1px solid #333;
  list-style: none;
  padding: 0.5rem 0; /* Reduce padding */
  margin: 0;
  min-width: 7.5rem; /* Ensure minimum width */
}

.language-menu li {
  padding: 0.5rem 0.5rem; /* Adjust padding */
  cursor: pointer;
}

.language-menu li:hover {
  background-color: #333;
}

/* Hamburger Menu Styles */
.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger-menu span {
  height: 2px;
  width: 25px;
  background-color: #e0e0e0;
  margin: 4px 0;
  transition: 0.4s;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column; /* Stack links vertically */
    align-items: center;
    position: absolute;
    top: 100%; /* Position below the navbar */
    right: 1rem; /* Align to the right */
    background-color: #1f1f1f; /* Match navbar background */
    padding: 1rem; /* Add padding */
    border: 1px solid #333; /* Add border */
    z-index: 999; /* Ensure it appears above other elements */
  }

  .nav-links.nav-links-open {
    display: flex;
  }

  .hamburger-menu {
    display: flex;
  }

  .nav-links li {
    margin-left: 0;
    margin-top: 0.5rem; /* Reduce space between links */
  }

  .language-menu li {
    padding: 0.2rem 0.2rem; /* Reduce padding for smaller screens */
  }
}
</style>