<template>
  <section id="products-demo" class="products-demo-section">
    <div class="container">
      <p>{{ $t('productsDemo.description') }}</p>

      <!-- Key Features -->
      <div class="content">
        <h3>{{ $t('productsDemo.keyFeaturesTitle') }}</h3>
        <div class="card-container">
          <div class="card cross-exchange">
            <img src="@/assets/CrossExchange.svg" alt="Cross Exchange Icon" />
            <h4 v-html="$t('productsDemo.crossExchangeExpertiseTitle')"></h4>
            <p>{{ $t('productsDemo.crossExchangeExpertiseDescription') }}</p>
          </div>
          <div class="card custody-model">
            <img src="@/assets/CustodyModel.svg" alt="Custody Model Icon" />
            <h4>{{ $t('productsDemo.custodyModelTitle') }}</h4>
            <p>{{ $t('productsDemo.custodyModelDescription') }}</p>
          </div>
          <div class="card custom-strategies">
            <img src="@/assets/CustomStrategies.svg" alt="Custom Strategies Icon" />
            <h4>{{ $t('productsDemo.customStrategiesTitle') }}</h4>
            <p>{{ $t('productsDemo.customStrategiesDescription') }}</p>
          </div>
          <div class="card risk-management">
            <img src="@/assets/Shield.svg" alt="Risk Management Icon" />
            <h4>{{ $t('productsDemo.riskManagementTitle') }}</h4>
            <p>{{ $t('productsDemo.riskManagementDescription') }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { TELEGRAM_URL } from '@/constants.js';

export default {
  name: 'ProductsDemo',
  data() {
    return {
      TELEGRAM_URL
    };
  },
  computed: {
    allValueImage() {
      return this.$i18n.locale === 'en' ? require('@/assets/AllValue_en.png') : require('@/assets/AllValue_cn.png');
    }
  },
  mounted() {
    const navbarHeight = document.querySelector('.navbar').offsetHeight;
    document.documentElement.style.setProperty('--scroll-margin-top', `${navbarHeight}px`);
  }
};
</script>

<style scoped>
.products-demo-section {
  background-color: #1f1f1f; /* Dark background */
  color: #e0e0e0; /* Light text color */
  padding-top: 3rem; /* Reduce top padding */
  scroll-margin-top: var(--scroll-margin-top); /* Use CSS variable for scroll margin */
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem; /* Add padding to the container */
}
h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #e0e0e0; /* Light text color */
}
h3 {
  font-size: 2rem; /* Adjusted font size for h3 */
  margin-bottom: 0.5rem; /* Reduce bottom margin */
  color: #e0e0e0; /* Light text color */
}
h4 {
  font-size: 1.25rem; /* Adjusted font size for h4 */
  margin-bottom: 0.5rem;
  color: #f39c12; /* Accent color */
}
p {
  font-size: 1.25rem;
  margin-bottom: 1rem; /* Reduce bottom margin */
  color: #b0b0b0; /* Slightly lighter text color */
}
.content {
  margin-bottom: 2rem;
}
.card-container {
  display: flex;
  justify-content: center; /* Center the cards horizontally */
  flex-wrap: wrap; /* Allow wrapping */
  gap: 2rem; /* Increase the gap between cards */
}
.card {
  flex: 1 1 calc(23% - 2rem); /* Four cards per row */
  max-width: calc(23% - 2rem); /* Ensure max width */
  background-color: #2a2a2a; /* Card background color */
  padding: 1rem;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow */
  text-align: center; /* Center text and icon */
}
.card h4 {
  font-size: 1.5rem;
  margin-bottom: 0.2rem; /* Reduce space below the title */
  color: #f39c12; /* Accent color */
}
.card img {
  width: 50px; /* Set the width of the icon */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 0.2rem; /* Reduce space between icon and text */
}
.card.custody-model img {
  content: url('@/assets/CustodyModel.svg');
}
.card.custom-strategies img {
  content: url('@/assets/CustomStrategies.svg');
}
.card.cross-exchange img {
  content: url('@/assets/CrossExchange.svg');
}
.card.risk-management img {
  content: url('@/assets/Shield.svg');
}
.card p {
  font-size: 1rem;
  color: #e0e0e0; /* Light text color */
}
.proven-success-image {
  width: 100%;
  height: auto;
  margin-top: 1rem;
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .card {
    flex: 1 1 calc(48% - 2rem); /* Two cards per row */
    max-width: calc(48% - 2rem); /* Ensure max width */
  }
}

@media (max-width: 768px) {
  .card {
    flex: 1 1 calc(100% - 2rem); /* One card per row */
    max-width: calc(100% - 2rem); /* Ensure max width */
  }
}
@media (max-width: 48rem) {
  .hero-section {
    scroll-margin-top: var(--scroll-margin-top); /* Use CSS variable for scroll margin */
  }
}
</style>